import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import SEO from "../components/seo"

const WPvehicle = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressWpVehicles.title}
      description={data.wordpressWpVehicles.excerpt}
    />
    <section className="container">
      <div className="columns is-desktop">
        <div className="column">
          {data.wordpressWpVehicles.featured_media && (
            <div className="product-featured-image">
              <Image
                fluid={
                  data.wordpressWpVehicles.featured_media.localFile
                    .childImageSharp.fluid
                }
                alt={data.wordpressWpVehicles.title}
              />
            </div>
          )}
          {data.wordpressWpVehicles.acf.product_images &&
            data.wordpressWpVehicles.acf.product_images.map(productImage => (
              <div
                key={productImage.localFile.childImageSharp.id}
                className="product-gallery-image"
              >
                <Image
                  fluid={productImage.localFile.childImageSharp.fluid}
                  alt={data.wordpressWpVehicles.title}
                />
              </div>
            ))}
        </div>
        <div className="column">
          {data.wordpressWpVehicles.title && (
            <h1 className="product-title is-size-2">
              {data.wordpressWpVehicles.title}
            </h1>
          )}
          {data.wordpressWpVehicles.acf.product_price && (
            <span className="product-price tag is-large">
              ${data.wordpressWpVehicles.acf.product_price}
            </span>
          )}
          {data.wordpressWpVehicles.content && (
            <div
              className="product-description"
              dangerouslySetInnerHTML={{
                __html: data.wordpressWpVehicles.content,
              }}
            />
          )}
        </div>
      </div>
    </section>
  </Layout>
)

export default WPvehicle

export const query = graphql`
  query($id: Int!) {
    wordpressWpVehicles(wordpress_id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        product_price
        product_images {
          localFile {
            childImageSharp {
              id
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
